<template>
  <div id="pm_mex_11_merchant_contract_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Contrato de comisión mercantil</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClassFull" style="height:400px; overflow-y:scroll">
                          <latest-investor-merchant-contract :fill-user-data="true" />
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Publicidad</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form">
                      <div class="vx-row">
                        <div :class="colClassFull">
                            <vx-card class="border-primary">
                                <latest-advertising-contract/>
                                <vs-switch class="mt-5" style="width: 90px;" v-model="acceptAdvertising">
                                  <span slot="on">Autorizo</span>
                                  <span slot="off">No autorizo</span>
                                </vs-switch>
                              </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div :class="colClassFull">
                          <div class="checkbox-display mt-base">
                            <vs-checkbox
                              name="terms"
                              v-model.lazy="acceptSecondaryMarketTerms">
                            </vs-checkbox>
                            <p>Acepto los <a class="inline-link-primary" target="_blank" rel="noopener" @click="popUpSecondaryMarketTermsContract=true" > Términos y Condiciones del Mercado Secundario</a>.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col sm:w-full md:w-1/2 lg:w-2/3 w-full"></div>
                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/3 w-full">
                  <vs-button id="pm_mex_11_merchant_contract_confirm_btn" class="w-full mt-5" @click="saveDataForm"
                    >Firmar y continuar</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <!-- Popup para ver terminos y condiciones de mercado secundario -->
    <vs-popup :active.sync="popUpSecondaryMarketTermsContract" title="Términos y condiciones"
      class="extended-popup header-primary">
      <latest-secondary-market-terms-conditions-contract />
    </vs-popup>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
import LatestAdvertisingContract from "@views/share/contracts/AdvertisingContract/LatestAdvertisingContract.vue";
import LatestSecondaryMarketTermsConditionsContract from "@views/investor/contracts/LatestSecondaryMarketTermsConditionsContract.vue";

export default {
  mixins: [inputHelper],
  components: {LatestInvestorMerchantContract, LatestAdvertisingContract, LatestSecondaryMarketTermsConditionsContract},
  props: ["onboardingStepData"],
  data() {
    return {
      acceptAdvertising: true,
      errorMssg: null,
      colClassFull: "vx-col w-full",
      currentLocation:null,
      contractInvestorMerchantType:3,
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      },
      acceptSecondaryMarketTerms:false,
      popUpSecondaryMarketTermsContract:false,
    };
  },
  async mounted(){
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    }
  },
  methods: {
    async saveDataForm() {
      this.showLoading(true, "Guardando información...");
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          contractType: this.contractInvestorMerchantType,
          acceptAdvertising: this.acceptAdvertising,
          geolocation: geolocation
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
    onErrorLocation(err){
      // console.log(err);
    },
  },
};
</script>
<style scoped>
.vx-card.border-primary{
    border-style: solid;
    border-width: 1px;
}
.checkbox-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
</style>
